/* ===========================
   1. CSS Variables for Themes
   =========================== */

/* Light Theme Variables */
:root {
  --background-color: #f8f9fa;
  --chat-box-bg: #ffffff;
  --user-message-bg: #d1e7dd;
  --bot-message-bg: #e2e3e5;
  --input-bg: #ffffff;
  --input-border: #ccc;
  --button-bg: #007bff;
  --button-hover-bg: #0056b3;
  --text-color: #000000;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: #c1c1c1;
  --scrollbar-thumb-hover: #a8a8a8;
  --dropdown-bg: #ffffff;
  --dropdown-text-color: #000000;
}

/* Dark Theme Variables */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --chat-box-bg: #1e1e1e;
    --user-message-bg: #2a2f32;
    --bot-message-bg: #3a3b3c;
    --input-bg: #1e1e1e;
    --input-border: #555;
    --button-bg: #1a73e8;
    --button-hover-bg: #135ab7;
    --text-color: #ffffff;
    --link-color: #8ab4f8;
    --link-hover-color: #4a90e2;
    --scrollbar-track: #2c2c2c;
    --scrollbar-thumb: #5a5a5a;
    --scrollbar-thumb-hover: #777;
    --dropdown-bg: #2a2f32;
    --dropdown-text-color: #ffffff;
  }
}

/* Language Selector Styling */
.language-selector {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.language-selector label {
  margin-right: 5px;
}

.language-selector select {
  background-color: var(--dropdown-bg);
  color: var(--dropdown-text-color);
  border: 1px solid var(--input-border);
  border-radius: 5px;
  padding: 5px;
}

/* User message styles */
.message.user {
  background-color: var(--user-message-bg);
  color: var(--text-color);
  border-radius: 15px;
  padding: 10px;
  margin: 5px 0;
  max-width: 80%;
}

/* Bot message styles */
.message.bot {
  background-color: var(--bot-message-bg);
  color: var(--text-color);
  border-radius: 15px;
  padding: 10px;
  margin: 5px 0;
  max-width: 80%;
}

/* ===========================
   2. Global Styles
   =========================== */

/* Container for the entire chat application */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  background-color: var(--background-color);
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

/* Chat box where messages are displayed */
.chat-box {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: var(--chat-box-bg);
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track); /* Firefox */
}

/* Custom scrollbar for WebKit browsers */
.chat-box::-webkit-scrollbar {
  width: 8px;
}

.chat-box::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 4px;
}

.chat-box::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
}

.chat-box::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Individual message container */
.message {
  margin: 5px 0;
  padding: 8px 12px;
  border-radius: 5px;
  max-width: 80%;
  word-wrap: break-word;
  color: var(--text-color);
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.3s forwards;
}

/* User messages */
.message.user {
  background-color: var(--user-message-bg);
  align-self: flex-end;
}

/* Bot messages */
.message.bot {
  background-color: var(--bot-message-bg);
  align-self: flex-start;
}

/* Link styling for messages */
.message a {
  color: var(--link-color);
  text-decoration: none;
}

.message a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* Dark mode adjustments for links */
@media (prefers-color-scheme: dark) {
  .message a {
    color: #8ab4f8;
  }

  .message a:hover {
    color: #4a90e2;
  }
}

/* Loading message specific styles */
.message.bot.loading {
  display: flex;
  align-items: center;
}

.message.bot.loading .spinner {
  margin-right: 10px;
}

/* Container for input field and buttons */
.input-container {
  display: flex;
  gap: 5px; /* Ensures uniform spacing between input and buttons */
  border-top: 1px solid #ddd;
  padding-top: 10px;
  background-color: var(--input-bg);
  align-items: center; /* Ensures all items in the container are aligned vertically */
}

/* Text input field */
.input-container input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  font-size: 16px;
  background-color: var(--input-bg);
  color: var(--text-color);
}

.image-upload-label {
  padding: 5px; /* Adjusted padding for compactness */
  background-color: #6c757d;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px; /* Icon size */
  display: flex;
  align-items: center; /* Center icon vertically */
  justify-content: center; /* Center icon horizontally */
  transition: background-color 0.3s ease;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
}

.image-upload-label:hover {
  background-color: #5a6268; /* Hover color specific to image upload */
}

/* Send button */
.input-container button {
  padding: 10px 20px;
  background-color: var(--button-bg);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  height: 40px; /* Set consistent height */
}

/* Manual search button */
.manual-search-button {
  padding: 10px 20px;
  background-color: #28a745; /* Green color for Search button */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  height: 40px; /* Set consistent height */
}

/* Hover effect for buttons */
.manual-search-button:hover {
  background-color: #218838; /* Specific hover color for Search button */
}

/* ===========================
   3. Login Form Styles (NEW)
   =========================== */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  padding: 20px;
}

.login-form {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-form h2 {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.input-group {
  margin-bottom: 1rem;
}

.input-field {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border 0.3s;
  font-size: 16px;
  background-color: var(--input-bg);
  color: var(--text-color);
}

.input-field:focus {
  border-color: #007bff;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 1rem;
  font-size: 14px;
}

.login-button {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
  background-color: var(--button-bg);
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.login-button:hover {
  background-color: var(--button-hover-bg);
}

.oauth-button {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
  background-color: #db4437; /* Google's red */
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.oauth-button:hover {
  background-color: #c3362f; /* Darker shade of Google's red */
}

@media (max-width: 600px) {
  .login-form {
    padding: 1.5rem;
  }
}

/* ===========================
   4. Markdown Elements Styling
   =========================== */
.message h1,
.message h2,
.message h3,
.message h4,
.message h5,
.message h6 {
  margin: 8px 0;
  font-weight: bold;
  color: var(--text-color);
}

.message p {
  margin: 8px 0;
  line-height: 1.5;
  color: var(--text-color);
}

.message strong {
  font-weight: bold;
}

.message em {
  font-style: italic;
}

.message code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
}

.message pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  margin: 8px 0;
}

.message ul,
.message ol {
  margin: 8px 0 8px 20px;
  padding: 0;
  color: var(--text-color);
}

.message li {
  margin: 4px 0;
}

.message blockquote {
  border-left: 4px solid #ccc;
  padding-left: 10px;
  color: #666;
  margin: 8px 0;
  font-style: italic;
}

.message img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 8px 0;
}

/* ===========================
   5. Spinner Styles
   =========================== */
.spinner {
  width: 20px;
  height: 20px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #007bff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes bounce {
  0%, 100% { transform: scale(0.0); }
  50% { transform: scale(1.0); }
}

/* ===========================
   6. Animations
   =========================== */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ===========================
   7. Responsive Design Enhancements
   =========================== */
@media (max-width: 600px) {
  .chat-container {
    padding: 10px;
  }

  .input-container button {
    padding: 10px;
    font-size: 14px;
  }

  .input-container input[type="text"] {
    padding: 8px;
    font-size: 14px;
  }

  .message {
    max-width: 100%;
  }
}

/* ===========================
   8. Enhanced Accessibility
   =========================== */
.input-container button {
  color: #ffffff;
}

@media (prefers-color-scheme: dark) {
  .message blockquote {
    border-left: 4px solid #555;
    color: #aaa;
  }

  .message a {
    color: var(--link-color);
  }

  .message a:hover {
    color: var(--link-hover-color);
  }
}
